<template>
  <ul :class="`list-disc ${extraClass}`">
    <list-li
      v-for="item in items"
      :key="item"
      :content="item"
      :class="extraLiClass"
    />
  </ul>
</template>

<script>
import ListLi from './li'
export default {
  name: 'ListUl',
  components: {
    ListLi
  },
  props: {
    items: {
      default: () => [],
      required: true,
      type: Array
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    },
    extraLiClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

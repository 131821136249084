var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"sponsor__slider py-16"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full px-4"},[_c('client-only',[_c('agile',{ref:"sliderSponsor",attrs:{"dots":false,"slides-to-show":2,"slides-to-scroll":1,"autoplay":true,"autoplay-speed":2000,"nav-buttons":false,"responsive":[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4
                }
              }
            ]}},_vm._l((_vm.sponsors),function(sponsor){return _c('div',{key:sponsor.id,staticClass:"slide text-center relative px-4"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('sponsors')}},[_c('img',{attrs:{"src":sponsor.acf.logo.sizes.thumbnail,"alt":sponsor.title}})])],1)}),0)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <td v-html="content" />
</template>

<script>
export default {
  name: 'TableTd',
  props: {
    content: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

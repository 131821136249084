<template>
  <main>
    <header-page :photos="page.acf.header_images" spacer="black" />
    <section class="bg-black py-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-7/10 tablet:mx-auto px-4">
            <header-h2
              :content="page.title"
              color="white"
              casing="uppercase"
              family="header"
              align="center"
            />
            <div class="form">
              <form-main
                :form="{
                  showLabels: false,
                  showPlaceholders: true,
                  requiredText: '*',
                  buttonType: 'primary',
                  buttonText: $t('form.send'),
                  fields: [
                    {
                      type: 'input',
                      subtype: 'text',
                      name: 'contact_name',
                      label: $t('form.name'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'email',
                      name: 'contact_email',
                      label: $t('form.email'),
                      required: true
                    },
                    {
                      type: 'textarea',
                      name: 'contact_message',
                      label: $t('form.message'),
                      required: false
                    }
                  ]
                }"
              />
              <div v-if="message !== ''" v-html="message" class="mt-4" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderPage from '../components/headers/Page'
import wordpress from '~/service/wordpress'
export default {
  name: 'Contact',
  components: { HeaderPage },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Dex'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  data() {
    return {
      message: ''
    }
  },
  async asyncData({ app }) {
    const [page] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.contact')}`)
    ])

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    submitForm() {
      const params = new URLSearchParams()
      if (
        this.validateEmail(
          this.$el.getElementsByClassName('form_input')[1].value
        )
      ) {
        params.append(
          'contact_name',
          this.$el.getElementsByClassName('form_input')[0].value
        )
        params.append(
          'contact_email',
          this.$el.getElementsByClassName('form_input')[1].value
        )
        params.append(
          'contact_message',
          this.$el.getElementsByClassName('form_input')[2].value
        )
        this.$axios
          .post(this.$t('endpoints.contact_form'), params, {
            'Content-Type': 'application/x-www-form-urlencoded'
          })
          .then(() => {
            this.message = this.$t('form.thank_you')
          })
          .catch(() => {
            this.message = this.$t('form.fail')
          })
      } else {
        this.message = this.$t('form.false_email')
      }
    }
  }
}
</script>

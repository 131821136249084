<template>
  <main id="news_item_detail" class="news">
    <section class="pb-16">
      <div class="container">
        <div class="flex">
          <div class="w-full tablet:w-7/10 tablet:mx-auto px-4">
            <div class="news__item_detail">
              <div v-if="post.media" class="news__image_detail -mt-8">
                <img
                  :src="post.media.large"
                  :alt="post.title"
                  class="mx-auto"
                />
              </div>
              <div class="news__content_detail">
                <header-h2
                  :content="post.title"
                  color="blue-dark"
                  casing="uppercase"
                  family="header"
                  align="center"
                />

                <div v-html="post.content" />

                <div v-if="post.acf !== null">
                  <div v-if="post.acf.gallery" class="my-8">
                    <gallery
                      :images="post.gallery"
                      :index="index"
                      @close="index = null"
                    />
                    <div class="flex flex-wrap -mx-2">
                      <div
                        v-for="(image, imageIndex) in post.acf.gallery"
                        :key="image.id"
                        class="w-1/2 tablet:w-1/3 px-2 py-2"
                      >
                        <img
                          :src="image.url"
                          :alt="post.title"
                          @click="index = imageIndex"
                          class="cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-8">
                  <button-tertiary
                    :path="'news'"
                    :text="$t('components.news.overview')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Service',
  head() {
    const title =
      this.post.yoast.yoast_wpseo_title === ''
        ? this.post.title + ' - Dex'
        : this.post.yoast.yoast_wpseo_title
    const description =
      this.post.yoast.yoast_wpseo_metadesc === ''
        ? this.post.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.post.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'post' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.post.date
        },
        {
          property: 'og:article:modified_time',
          content: this.post.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.post.date_modified
        },

        {
          property: 'og:image',
          content: this.post.media.medium
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.post.media.medium
        }
      ]
    }
  },
  async asyncData({ params }) {
    const [post] = await Promise.all([wordpress.get(`post/${params.slug}`)])
    if (post.data.acf !== null) {
      if (post.data.acf.gallery) {
        const images = []
        for (let i = 0; i < post.data.acf.gallery.length; i++) {
          images.push(post.data.acf.gallery[i].url)
        }
        post.data.gallery = images
      }
    }

    return {
      post: post.data,
      index: null
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.post.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

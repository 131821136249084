<template>
  <img :src="src" :alt="alt" :class="extraClass" />
</template>

<script>
export default {
  name: 'ImageMain',
  props: {
    src: {
      default: '',
      required: true,
      type: String
    },
    alt: {
      default: '',
      required: false,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

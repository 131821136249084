<template>
  <section :class="`header__page`">
    <client-only>
      <div v-if="photos" class="images">
        <agile
          :dots="false"
          :autoplay-speed="3500"
          :autoplay="true"
          :nav-buttons="false"
          :fade="true"
          :pause-on-hover="false"
        >
          <div
            v-for="photo in photos"
            :key="photo.id"
            :style="`background-image: url('${photo.url}')`"
            class="image"
          />
        </agile>
      </div>
      <div v-else class="images">
        <div
          class="image"
          style="background-image: url('/images/stock-photo-team-athletes-on-mountain-bikes-start-concept-motorcross-stunts-bike-700973731.jpg')"
        />
      </div>
    </client-only>
    <div class="content">
      <div class="container">
        <div class="flex">
          <div class="w-full tablet:w-1/4 tablet:ml-auto px-4">
            <div
              v-if="event"
              class="bg-blue-dark text-white py-4 px-4 tablet:pb-56"
            >
              <header-h4
                content="Next race"
                color="white"
                align="center"
                casing="uppercase"
                mt="0"
                mb="0"
              />
              <header-h2
                :content="event.shortDate"
                color="white"
                weight="normal"
                align="center"
                mt="0"
                mb="0"
              />
              <header-h4
                :content="event.acf.plaatsnaam"
                color="white"
                align="center"
                mt="0"
                mb="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer" />
    <div :class="`spacer-bg spacer-bg-${spacer}`" />
  </section>
</template>

<script>
export default {
  name: 'HeaderPage',
  props: {
    spacer: {
      default: 'white',
      required: false,
      type: String
    },
    photos: {
      default: () => [],
      required: false,
      type: Array
    }
  },

  computed: {
    event() {
      const events = this.$store.getters.getEvents
      const today = new Date().getTime()
      const futureEvents = []
      let event = false

      const months = [
        '',
        'jan',
        'feb',
        'maa',
        'apr',
        'mei',
        'jun',
        'jul',
        'aug',
        'sep',
        'okt',
        'nov',
        'dec'
      ]

      for (let i = 0; i < events.length; i++) {
        const temp = new Date(
          events[i].acf.datum.slice(-4) +
            '/' +
            events[i].acf.datum.slice(3, 5) +
            '/' +
            events[i].acf.datum.slice(0, 2)
        )
        events[i].dateOfRace = temp
        if (temp.getTime() > today) {
          events[i].shortDate =
            events[i].acf.datum.slice(0, 2) +
            ' ' +
            months[parseInt(events[i].acf.datum.slice(3, 5))]
          // i = events.length
          futureEvents.push(events[i])
        }
      }

      futureEvents.sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.

        return new Date(a.dateOfRace) - new Date(b.dateOfRace)
      })

      return event = futureEvents[0]
    }
  }
}
</script>

<style lang="scss">
.agile {
  width: 100%;
  height: 100%;
}
.agile__list,
.agile__track,
.agile__slides {
  height: 100%;
}
section {
  &.header {
    &__page {
      .spacer-bg-white {
        border-color: transparent transparent #fff transparent;
      }

      .spacer-bg-black {
        border-color: transparent transparent #000 transparent;
      }
    }
  }
}
</style>

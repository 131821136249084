<template>
  <option :value="value" v-html="label" :selected="isDefault" />
</template>

<script>
export default {
  name: 'FormOption',
  props: {
    value: {
      default: '',
      required: true,
      type: String
    },
    label: {
      default: '',
      required: true,
      type: String
    },
    isDefault: {
      default: false,
      required: false,
      type: Boolean
    }
  }
}
</script>

<template>
  <div
    :class="
      `form_field__control form_field__control__checkbox ${
        inline ? 'form_field__control__inline' : 'form_field__control__stacked'
      }`
    "
  >
    <input
      :id="id"
      :value="value"
      :name="`${name}[]`"
      :checked="isDefault"
      :required="required"
      type="checkbox"
      class="form_field__control__input"
    />
    <form-label :label="label" :forId="id" />
  </div>
</template>

<script>
import FormLabel from './label'
export default {
  name: 'FormCheckbox',
  components: { FormLabel },
  props: {
    required: {
      default: false,
      required: false,
      type: Boolean
    },
    inline: {
      default: false,
      required: false,
      type: Boolean
    },
    value: {
      default: '',
      required: true,
      type: String
    },
    label: {
      default: '',
      required: true,
      type: String
    },
    name: {
      default: '',
      required: true,
      type: String
    },
    id: {
      default: '',
      required: true,
      type: String
    },
    isDefault: {
      default: false,
      required: false,
      type: Boolean
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

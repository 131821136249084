<template>
  <tfoot>
    <table-tr v-for="row in rows" :key="row" :data="row" />
  </tfoot>
</template>

<script>
import TableTr from './tr'
export default {
  name: 'TableTfoot',
  components: { TableTr },
  props: {
    rows: {
      default: () => [],
      required: false,
      type: Array
    }
  }
}
</script>

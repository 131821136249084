<template>
  <footer id="layout_footer">
    <div class="container">
      <div class="w-full tablet:w-1/2 px-4">
        <p>
          <strong>Dex van den Broek</strong><br /><a
            href="mailto:info@dexvandenbroek.nl"
            >info@dexvandenbroek.nl</a
          >
        </p>
        <p><a href="https://mcdeurne.nl/" target="_blank">MC Deurne</a></p>
        <p class="text-4xl flex flex-row items-center">
          <a href="https://www.facebook.com/dex.vandenbroek" class="mr-4" target="_blank"
            ><i class="fab fa-facebook"
          /></a>
          <a href="https://www.instagram.com/dexvdbroek" class="mr-4" target="_blank"
            ><i class="fab fa-instagram"
          /></a>
          <a href="https://www.youtube.com/channel/UCzsEBqUhy8H4GubNPcpWEpw" target="_blank"
            ><i class="fab fa-youtube"
          /></a>
        </p>
        <p>
          <small>
              <nuxt-link :to="localePath('privacy')" v-html="$t('menu.privacy')"/><br/>Stichting "MX Force Eleven" - Grote Bottel 10, Deurne - KVK: 74259784
          </small>
        </p>
      </div>
    </div>
    <cookie-law
      :buttonText="$t('cookies.buttonText')"
      :buttonLink="localePath('privacy')"
      :buttonLinkText="$t('cookies.linkText')"
      :message="$t('cookies.message')"
      theme="mytheme"
    />
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
.Cookie--mytheme {
  @apply text-white px-8 py-4 text-base bg-orange-dark;
}
.Cookie--mytheme .Cookie__button:first-child {
  @apply inline text-white underline;
  &:hover {
    @apply text-black;
  }
}
.Cookie--mytheme .Cookie__button:last-child {
  @apply inline-block text-center px-4 py-2 text-base leading-normal bg-transparent border-2 border-solid border-white text-white font-bold uppercase text-xl relative z-10;

  &:before {
    @apply absolute top-0 right-0 bottom-0 left-0 bg-white origin-top-left transition-all duration-200;
    z-index: -1;
    transform: scaleX(0);
    content: '';
  }

  &:hover {
    @apply text-orange-dark;

    &:before {
      transform: scaleX(1);
    }
  }
}
</style>

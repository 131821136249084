<template>
  <tr>
    <table-td v-for="cell in data" :key="cell" :content="cell" />
  </tr>
</template>

<script>
import TableTd from './td'
export default {
  name: 'TableTr',
  components: { TableTd },
  props: {
    data: {
      default: () => [],
      required: false,
      type: Array
    },
    type: {
      default: 'td',
      required: false,
      type: String
    }
  }
}
</script>

<template>
  <section class="sponsor__slider py-16">
    <div class="container">
      <div class="flex">
        <div class="w-full px-4">
          <client-only>
            <agile
              ref="sliderSponsor"
              :dots="false"
              :slides-to-show="2"
              :slides-to-scroll="1"
              :autoplay="true"
              :autoplay-speed="2000"
              :nav-buttons="false"
              :responsive="[
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 4
                  }
                }
              ]"
            >
              <div
                v-for="sponsor in sponsors"
                :key="sponsor.id"
                class="slide text-center relative px-4"
              >
                <nuxt-link :to="localePath('sponsors')">
                  <img
                    :src="sponsor.acf.logo.sizes.thumbnail"
                    :alt="sponsor.title"
                  />
                </nuxt-link>
              </div>
            </agile>
          </client-only>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SliderSponsor',

  computed: {
    sponsors() {
      return this.$store.getters.getSponsors
    }
  }
}
</script>

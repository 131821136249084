<template>
  <form
    id="form"
    @submit.prevent
    @submit="$parent.submitForm"
    :class="`form ${form.extraClass}`"
  >
    <div v-for="field in form.fields" :key="field.name" class="form_element">
      <div v-if="field.type === 'input'">
        <form-label
          v-if="form.showLabels"
          :label="field.label + (field.required ? ' ' + form.requiredText : '')"
          :forId="`form_field_${field.name}`"
        />
        <form-input
          :type="field.subtype"
          :name="field.name"
          :required="field.required"
          :placeholder="
            form.showPlaceholders
              ? field.label + (field.required ? ' ' + form.requiredText : '')
              : ''
          "
          :extraClass="form.extraInputClass"
        />
      </div>
      <div v-else-if="field.type === 'radio'">
        <form-label
          :label="field.label + (field.required ? ' ' + form.requiredText : '')"
          :forId="`form_field_${field.name}`"
        />
        <form-radio
          v-for="option in field.options"
          :key="`form_field_${field.name}_${option.value}`"
          :inline="field.inline"
          :value="option.value"
          :label="option.label"
          :required="field.required"
          :isDefault="option.default"
          :name="field.name"
          :id="`form_field_${field.name}_${option.value}`"
          :extraClass="form.extraInputClass"
        />
      </div>
      <div v-else-if="field.type === 'checkbox'">
        <form-label
          :label="field.label + (field.required ? ' ' + form.requiredText : '')"
          :forId="`form_field_${field.name}`"
        />
        <form-checkbox
          v-for="option in field.options"
          :key="`form_field_${field.name}_${option.value}`"
          :inline="field.inline"
          :value="option.value"
          :label="option.label"
          :required="field.required"
          :isDefault="option.default"
          :name="field.name"
          :id="`form_field_${field.name}_${option.value}`"
          :extraClass="form.extraInputClass"
        />
      </div>
      <div v-else-if="field.type === 'select'">
        <form-label
          :label="field.label + (field.required ? ' ' + form.requiredText : '')"
          :forId="`form_field_${field.name}`"
        />
        <form-select
          :label="field.label"
          :required="field.required"
          :name="field.name"
          :options="field.options"
          :id="`form_field_${field.name}`"
          :extraClass="form.extraInputClass"
        />
      </div>
      <div v-else-if="field.type === 'textarea'">
        <form-label
          v-if="form.showLabels"
          :label="field.label + (field.required ? ' ' + form.requiredText : '')"
          :forId="`form_field_${field.name}`"
        />
        <form-textarea
          :name="field.name"
          :required="field.required"
          :placeholder="
            form.showPlaceholders
              ? field.label + (field.required ? ' ' + form.requiredText : '')
              : ''
          "
          :extraClass="form.extraInputClass"
        />
      </div>
    </div>

    <div v-if="form.buttonType === 'primary'">
      <button-primary :text="form.buttonText" type="submit-form" />
    </div>
    <div v-else-if="form.buttonType === 'secondary'">
      <button-secondary :text="form.buttonText" type="submit-form" />
    </div>
    <div v-else-if="form.buttonType === 'tertiary'">
      <button-tertiary :text="form.buttonText" type="submit-form" />
    </div>
    <div v-else>
      <button-primary :text="form.buttonText" type="submit-form" />
    </div>
  </form>
</template>

<script>
import FormLabel from './label'
export default {
  name: 'FormMain',
  components: { FormLabel },
  props: {
    form: {
      default: () => {},
      required: false,
      type: Object
    }
  }
}
</script>

<template>
  <main id="photo_video">
    <header-page :photos="page.acf.header_images" />
    <section id="latest_media" class="py-16">
      <div class="container">
<!--        <gallery :images="imagesArchive" :index="index" @close="index = null" />-->
        <client-only>
            <gallery :images="images" :index="index" @close="index = null" />

            <div v-for="year in getYears()" :key="year">
                <header-h3
                    :content="`Seizoen ${year}`"
                    color="blue-dark"
                    casing="uppercase"
                    family="header"
                    align="center"
                    mb="4"
                />
                <masonry
                    :cols="{ default: 3, 768: 1 }"
                    :gutter="0"
                    class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
                >
                    <div
                        v-for="(post, index) in getPostsByYear(year)"
                        :key="index"
                        class="images-video w-full mb-8 px-4 py-0"
                    >
                        <div v-if="post.acf.foto" class="h-full">
                            <div @click="setIndex(post.imageIndex)" class="cursor-pointer">
                                <img :src="post.acf.foto.sizes.thumbnail" :alt="post.title" />
                            </div>
                        </div>
                        <div v-else class="h-full">
                            <iframe
                                :src="`https://www.youtube.com/embed/${post.acf.youtube_video_id}`"
                                class="w-full h-full"
                            />
                        </div>
                    </div>
                </masonry>
            </div>
        </client-only>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderPage from '../components/headers/Page'
import wordpress from '~/service/wordpress'
export default {
  name: 'PhotoVideo',
  components: { HeaderPage },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Dex'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [page, photoVideoArchive, photoVideo] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.photo_video')}`),
      wordpress.get(
          `/${app.i18n.t(
              'endpoints.photo_video_items'
          )}?orderby=date&per_page=100`
      ),
      wordpress.get(
          `/${app.i18n.t(
              'endpoints.photo_video_items'
          )}?orderby=rand&per_page=100`
      )
    ])

    let j        = 0
    const images = []

      for (let f = 0; f < photoVideo.data.length; f++) {
        if (photoVideo.data[f].acf.foto) {
          photoVideo.data[f].imageIndex = j
          images.push(photoVideo.data[f].acf.foto.sizes.large)
          j++
        }
      }

    return {
      page: page.data,
      photoVideo: photoVideo.data,
      photoVideoArchive: photoVideoArchive.data,
      images,
      // imagesArchive,
      index: null
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    setIndex(index) {
      this.index = index
    },

    getYears() {
      const years = new Set(this.photoVideo.map(post => this.extractYearFromTitle(post.title)));
      return Array.from(years).sort((a, b) => b - a); // Sorteer jaren in aflopende volgorde
    },

    getPostsByYear(year) {
      return this.photoVideo.filter(post => this.extractYearFromTitle(post.title) === year);
    },

    extractYearFromTitle(title) {
      const match = title.match(/DEX(\d{4})/);
      return match ? match[1] : null;
    },
  }
}
</script>
<style>
.blueimp-gallery,
.blueimp-gallery > .slides > .slide > .slide-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.blueimp-gallery > .slides > .slide > .slide-content {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
}
.blueimp-gallery {
  position: fixed;
  z-index: 999999;
  overflow: hidden;
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  display: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}
.blueimp-gallery-carousel {
  position: relative;
  z-index: auto;
  margin: 1em auto;
  padding-bottom: 56.25%;
  box-shadow: 0 0 10px #000;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.blueimp-gallery-display {
  display: block;
  opacity: 1;
}
.blueimp-gallery > .slides {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.blueimp-gallery-carousel > .slides {
  position: absolute;
}
.blueimp-gallery > .slides > .slide {
  position: relative;
  float: left;
  height: 100%;
  text-align: center;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.blueimp-gallery > .slides > .slide-loading {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}
.blueimp-gallery > .slides > .slide-loading > .slide-content {
  opacity: 0;
}
.blueimp-gallery > .slides > .slide-error {
  /*background: url(../img/error.png) center no-repeat;*/
}
.blueimp-gallery > .slides > .slide-error > .slide-content {
  display: none;
}
.blueimp-gallery > .next,
.blueimp-gallery > .prev {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -23px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 2px #000;
  text-align: center;
  background: #222;
  background: rgba(0, 0, 0, 0.5);
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 3px solid #fff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  cursor: pointer;
  display: none;
}
.blueimp-gallery > .next {
  left: auto;
  right: 15px;
}
.blueimp-gallery > .close,
.blueimp-gallery > .title {
  position: absolute;
  top: 15px;
  left: 15px;
  margin: 0 40px 0 0;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  text-shadow: 0 0 2px #000;
  opacity: 0.8;
  display: none;
}
.blueimp-gallery > .close {
  padding: 15px;
  right: 15px;
  left: auto;
  margin: -15px;
  font-size: 80px;
  text-decoration: none;
  cursor: pointer;
}
.blueimp-gallery > .play-pause {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 15px;
  height: 15px;
  /*background: url(../img/play-pause.png) 0 0 no-repeat;*/
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
.blueimp-gallery-playing > .play-pause {
  background-position: -15px 0;
}
.blueimp-gallery-controls > .close,
.blueimp-gallery-controls > .next,
.blueimp-gallery-controls > .play-pause,
.blueimp-gallery-controls > .prev,
.blueimp-gallery-controls > .title {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.blueimp-gallery-left > .prev,
.blueimp-gallery-right > .next,
.blueimp-gallery-single > .next,
.blueimp-gallery-single > .play-pause,
.blueimp-gallery-single > .prev {
  display: none;
}
.blueimp-gallery > .close,
.blueimp-gallery > .next,
.blueimp-gallery > .play-pause,
.blueimp-gallery > .prev,
.blueimp-gallery > .slides > .slide > .slide-content {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.blueimp-gallery > .close:hover,
.blueimp-gallery > .next:hover,
.blueimp-gallery > .play-pause:hover,
.blueimp-gallery > .prev:hover,
.blueimp-gallery > .title:hover {
  color: #fff;
  opacity: 1;
}
body:last-child .blueimp-gallery > .slides > .slide-error {
  /*background-image: url(../img/error.svg);*/
}
body:last-child .blueimp-gallery > .play-pause {
  width: 20px;
  height: 20px;
  background-size: 40px 20px;
  /*background-image: url(../img/play-pause.svg);*/
}
body:last-child .blueimp-gallery-playing > .play-pause {
  background-position: -20px 0;
}
* + html .blueimp-gallery > .slides > .slide {
  min-height: 300px;
}
* + html .blueimp-gallery > .slides > .slide > .slide-content {
  position: relative;
}
.blueimp-gallery > .indicator {
  position: absolute;
  top: auto;
  right: 15px;
  bottom: 15px;
  left: 15px;
  margin: 0 40px;
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 10px;
  display: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.blueimp-gallery > .indicator > li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 6px 3px 0 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid transparent;
  background: #ccc;
  background: rgba(255, 255, 255, 0.25) center no-repeat;
  border-radius: 5px;
  box-shadow: 0 0 2px #000;
  opacity: 0.5;
  cursor: pointer;
}
* + html .blueimp-gallery > .indicator > li {
  display: inline;
}
.blueimp-gallery > .indicator > .active,
.blueimp-gallery > .indicator > li:hover {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
.blueimp-gallery > .indicator > li:after {
  opacity: 0;
  display: block;
  position: absolute;
  content: '';
  top: -5em;
  width: 75px;
  height: 75px;
  transition: transform 0.6s ease-out, opacity 0.4s ease-out;
  transform: translateX(-50%) translateY(0) translateZ(0);
  pointer-events: none;
}
.blueimp-gallery > .indicator > li:hover:after {
  opacity: 1;
  border-radius: 50%;
  background: inherit;
  transform: translateX(-50%) translateY(-5px) translateZ(0);
}
.blueimp-gallery > .indicator > .active:after {
  display: none;
}
.blueimp-gallery-controls > .indicator {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.blueimp-gallery-single > .indicator {
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-content > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.blueimp-gallery > .slides > .slide > .video-content > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-content > iframe {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.blueimp-gallery > .slides > .slide > .video-playing > iframe {
  top: 0;
}
.blueimp-gallery > .slides > .slide > .video-content > a {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: -64px auto 0;
  width: 128px;
  height: 128px;
  /*background: url(../img/video-play.png) center no-repeat;*/
  opacity: 0.8;
  cursor: pointer;
}
.blueimp-gallery > .slides > .slide > .video-playing > a,
.blueimp-gallery > .slides > .slide > .video-playing > img {
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-playing > video {
  display: block;
}
.blueimp-gallery > .slides > .slide > .video-loading > a {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}
* + html .blueimp-gallery > .slides > .slide > .video-content {
  height: 100%;
}
* + html .blueimp-gallery > .slides > .slide > .video-content > a {
  left: 50%;
  margin-left: -64px;
}
.blueimp-gallery > .slides > .slide > .video-content > a:hover {
  opacity: 1;
}
body:last-child
  .blueimp-gallery
  > .slides
  > .slide
  > .video-content:not(.video-loading)
  > a {
  /*background-image: url(../img/video-play.svg);*/
}
</style>

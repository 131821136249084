var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('header-page',{attrs:{"photos":_vm.page.acf.header_images,"spacer":"black"}}),_vm._v(" "),_c('section',{staticClass:"bg-black py-16"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full tablet:w-7/10 tablet:mx-auto px-4"},[_c('header-h2',{attrs:{"content":_vm.page.title,"color":"white","casing":"uppercase","family":"header","align":"center"}}),_vm._v(" "),_c('div',{staticClass:"form"},[_c('form-main',{attrs:{"form":{
                showLabels: false,
                showPlaceholders: true,
                requiredText: '*',
                buttonType: 'primary',
                buttonText: _vm.$t('form.send'),
                fields: [
                  {
                    type: 'input',
                    subtype: 'text',
                    name: 'contact_name',
                    label: _vm.$t('form.name'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'email',
                    name: 'contact_email',
                    label: _vm.$t('form.email'),
                    required: true
                  },
                  {
                    type: 'textarea',
                    name: 'contact_message',
                    label: _vm.$t('form.message'),
                    required: false
                  }
                ]
              }}}),_vm._v(" "),(_vm.message !== '')?_c('div',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e()],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
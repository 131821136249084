<template>
  <main id="homepage">
    <header-page :photos="page.acf.header_images" />
    <section id="latest_news" class="py-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/2 px-4 tablet:pr-0 tablet:py-6">
            <nuxt-link
              :to="
                localePath({
                  name: 'news-slug',
                  params: { slug: posts[0].slug }
                })
              "
            >
              <img :src="posts[0].media.large" :alt="posts[0].title" />
            </nuxt-link>
          </div>
          <div
            class="w-full tablet:w-1/2 px-12 py-12 bg-black flex items-center justify-center"
          >
            <div>
              <header-h4
                :content="posts[0].title"
                color="white"
                casing="uppercase"
                leading="none"
                mt="0"
                mb="0"
              />
              <div v-html="posts[0].excerpt" class="my-4 text-white" />
              <p>
                <nuxt-link
                  :to="
                    localePath({
                      name: 'news-slug',
                      params: { slug: posts[0].slug }
                    })
                  "
                  v-html="$t('components.news.read_more')"
                  class="text-white hover:text-orange-light"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="overview_news" class="py-8">
      <div class="container">
        <client-only>
          <masonry
            :cols="{ default: 3, 768: 1 }"
            :gutter="0"
            class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
          >
            <div
              v-for="(post, index) in posts.slice(1, 4)"
              :key="index"
              class="w-full mb-8"
              style="padding: 0 !important"
            >
              <news-item
                v-if="post.media"
                :title="post.title"
                :content="post.excerpt"
                :slug="post.slug"
                :image-url="post.media.medium"
              />
              <news-item
                v-else
                :title="post.title"
                :content="post.excerpt"
                :slug="post.slug"
              />
            </div>
          </masonry>
        </client-only>
      </div>
    </section>
    <section
      id="spacer"
      :style="'background-image: url(' + page.acf.image_full_screen_below_news.url + ')'"
    >
      <div class="spacer" />
      <div :class="`spacer-bg spacer-bg-white`" />
    </section>
    <section id="latest_media" class="py-16">
      <div class="container">
        <client-only>
          <masonry
            :cols="{ default: 4, 768: 1 }"
            :gutter="0"
            class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
          >
            <div
              v-for="(post, index) in photoVideo"
              :key="index"
              class="w-full mb-8 px-4 py-0"
            >
              <div v-if="post.acf.foto">
                <img :src="post.acf.foto.sizes.thumbnail" :alt="post.title" />
              </div>
              <div v-else>
                <iframe
                  :src="
                    `https://www.youtube.com/embed/${post.acf.youtube_video_id}`
                  "
                  class="w-full"
                />
              </div>
            </div>
          </masonry>
        </client-only>
      </div>
    </section>
    <slider-sponsor />
  </main>
</template>

<script>
import HeaderPage from '../components/headers/Page'
import NewsItem from '../components/news/Item'
import SliderSponsor from '../components/sliders/Sponsors'
import wordpress from '~/service/wordpress'

export default {
  name: 'Homepage',
  components: { SliderSponsor, HeaderPage, NewsItem },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Dex'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [page, posts, photoVideo] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.homepage')}`),
      wordpress.get(`/${app.i18n.t('endpoints.news')}?per_page=4`),
      wordpress.get(`/${app.i18n.t('endpoints.photo_video_items')}?per_page=4`)
    ])

    return {
      page: page.data,
      posts: posts.data,
      photoVideo: photoVideo.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
<style lang="scss">
section {
  &#spacer {
    @apply relative bg-center bg-no-repeat bg-cover;
    height: 75vh;

    .spacer {
      @apply absolute w-full bg-top bg-no-repeat bg-cover z-40;
      background-image: url('~assets/images/overlay-2.svg');
      height: 350px;
      bottom: -100px;
    }

    .spacer-bg {
      @apply absolute w-full;
      bottom: -20px;
      height: 175px;
      border-style: solid;
      border-width: 0 0 245px 2000px;
    }

    .spacer-bg-white {
      border-color: transparent transparent #fff transparent;
    }

    .spacer-bg-black {
      border-color: transparent transparent #000 transparent;
    }
  }
}

@media only screen and (max-width: theme('screens.tablet')) {
  section {
    &#spacer {
      height: 45vh;

      .spacer,
      .spacer-bg {
        @apply hidden;
      }
    }
  }
}
</style>

<template>
  <table :class="`table ${extraClass}`">
    <table-thead
      v-if="table.thead && table.thead.length > 0"
      :rows="table.thead"
    />
    <table-tbody
      v-if="table.tbody && table.tbody.length > 0"
      :rows="table.tbody"
    />
    <table-tfoot
      v-if="table.tfoot && table.tfoot.length > 0"
      :rows="table.tfoot"
    />
  </table>
</template>

<script>
import TableTbody from './tbody'
import TableThead from './thead'
import TableTfoot from './tfoot'
export default {
  name: 'TableMain',
  components: { TableTfoot, TableThead, TableTbody },
  props: {
    table: {
      default: () => {},
      required: false,
      type: Object
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

<template>
  <label :class="extraClass" :for="forId" v-html="label" />
</template>

<script>
export default {
  name: 'FormLabel',
  props: {
    label: {
      default: '',
      required: true,
      type: String
    },
    forId: {
      default: '',
      required: true,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

<template>
  <li v-html="content" :class="extraLiClass" />
</template>

<script>
export default {
  name: 'ListLi',
  props: {
    content: {
      default: '',
      required: true,
      type: String
    },
    extraLiClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

<template>
  <main id="events">
    <header-page :photos="page.acf.header_images" />
    <section class="py-16 pb-8">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <header-h2
              :content="page.title"
              color="blue-dark"
              casing="uppercase"
              family="header"
              align="center"
            />
            <div v-html="page.content" />
          </div>
        </div>
      </div>
    </section>
    <section class="py-8">
      <div class="container">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full px-4">
              <header-h3
                content="Aankomende evenementen"
                color="blue-dark"
                casing="uppercase"
                family="header"
                align="center"
                mb="4"
              />
            </div>
          </div>
        </div>
        <client-only>
          <masonry
            :cols="{ default: 3, 768: 1 }"
            :gutter="0"
            class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
          >
            <div
              v-for="(post, index) in futureEvents"
              :key="index"
              class="w-full mb-8 px-4"
            >
              <div class="news__item">
                <div class="news__content">
                  <header-h6
                    :content="post.title"
                    color="black"
                    casing="uppercase"
                    leading="none"
                    mt="0"
                    mb="0"
                  />
                  <div class="my-4">
                    {{ post.acf.datum }} / {{ post.acf.plaatsnaam }}
                  </div>
                </div>
              </div>
            </div>
          </masonry>
        </client-only>
      </div>
    </section>
    <section v-if="pastEvents.length > 0" class="py-8">
      <div class="container">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full px-4">
              <header-h3
                content="Evenementen archief"
                color="blue-dark"
                casing="uppercase"
                family="header"
                align="center"
                mb="4"
              />
            </div>
          </div>
        </div>
        <client-only>
          <masonry
            :cols="{ default: 3, 768: 1 }"
            :gutter="0"
            class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
          >
            <div
              v-for="(post, index) in pastEvents"
              :key="index"
              class="w-full mb-8 px-4"
            >
              <div class="news__item">
                <div class="news__content">
                  <header-h6
                    :content="post.title"
                    color="black"
                    casing="uppercase"
                    leading="none"
                    mt="0"
                    mb="0"
                  />
                  <div class="my-4">
                    {{ post.acf.datum }} / {{ post.acf.plaatsnaam }}
                  </div>
                </div>
              </div>
            </div>
          </masonry>
        </client-only>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderPage from '../components/headers/Page'
import wordpress from '~/service/wordpress'
export default {
  name: 'Events',
  components: { HeaderPage },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Dex'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [page, posts] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.events')}`),
      wordpress.get(`${app.i18n.t('endpoints.event_items')}?per_page=500`)
    ])

    const pastEvents = []
    const futureEvents = []
    const today = new Date().getTime()

    for (let i = 0; i < posts.data.length; i++) {
      const temp = new Date(
        posts.data[i].acf.datum.slice(-4) +
          '/' +
          posts.data[i].acf.datum.slice(3, 5) +
          '/' +
          posts.data[i].acf.datum.slice(0, 2)
      )
      posts.data[i].dateItem = temp
      if (temp.getTime() > today) {
        futureEvents.push(posts.data[i])
      } else {
        pastEvents.push(posts.data[i])
      }
    }

    pastEvents.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.dateItem) - new Date(a.dateItem)
    })
    futureEvents.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.dateItem) - new Date(b.dateItem)
    })

    return {
      page: page.data,
      posts: posts.data,
      pastEvents,
      futureEvents
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

<template>
  <main class="news">
    <section class="py-8">
      <div class="container">
        <client-only>
          <masonry
            :cols="{ default: 3, 768: 1 }"
            :gutter="0"
            class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
          >
            <div
              v-for="(post, index) in posts"
              :key="index"
              class="w-full mb-8"
              style="padding: 0 !important"
            >
              <news-item
                v-if="post.media"
                :title="post.title"
                :content="post.excerpt"
                :slug="post.slug"
                :image-url="post.media.medium"
              />
              <news-item
                v-else
                :title="post.title"
                :content="post.excerpt"
                :slug="post.slug"
              />
            </div>
          </masonry>
        </client-only>
      </div>
    </section>
  </main>
</template>

<script>
import NewsItem from '../../components/news/Item'
import wordpress from '~/service/wordpress'

export default {
  name: 'Service',
  components: { NewsItem },

  head() {
    const title = 'News - Dex'
    const description =
      'Check out the latest news about Dex, out `no meat` products and our availability.'

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.posts[0].date
        },
        {
          property: 'og:article:modified_time',
          content: this.posts[0].date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.posts[0].date_modified
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [posts] = await Promise.all([
      wordpress.get(`/${app.i18n.t('endpoints.news')}?per_page=100`)
    ])

    return {
      posts: posts.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'News',
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

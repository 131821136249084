<template>
  <header :class="$parent.scrolled ? 'shrinked' : ''">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-1/3 laptop:w-1/6">
          <div class="logo">
            <nuxt-link :to="localePath('index')"
              ><img src="~assets/images/logo.svg" alt="Dex"
            /></nuxt-link>
          </div>
        </div>
        <div class="w-2/3 laptop:w-5/6 px-4 text-right">
          <div v-if="!$parent.mobile" class="main_menu">
            <ul>
              <li>
                <nuxt-link v-html="$t('menu.home')" :to="localePath('index')" />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.about_dex')"
                  :to="localePath('about-dex')"
                />
              </li>
              <li>
                <nuxt-link v-html="$t('menu.news')" :to="localePath('news')" />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.photo_video')"
                  :to="localePath('photo-video')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.events')"
                  :to="localePath('events')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.sponsors')"
                  :to="localePath('sponsors')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.friends')"
                  :to="localePath('friends')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.contact')"
                  :to="localePath('contact')"
                />
              </li>
            </ul>
          </div>
          <div
            :class="{ open: showMenu }"
            @click="changeMobilemenu"
            v-if="$parent.mobile"
            class="hamburger"
          >
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showMenu && $parent.mobile" class="mobile_menu">
      <div class="container">
        <div class="flex">
          <div class="w-full">
            <ul>
              <li>
                <nuxt-link v-html="$t('menu.home')" :to="localePath('index')" />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.about_dex')"
                  :to="localePath('about-dex')"
                />
              </li>
              <li>
                <nuxt-link v-html="$t('menu.news')" :to="localePath('news')" />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.photo_video')"
                  :to="localePath('photo-video')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.events')"
                  :to="localePath('events')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.sponsors')"
                  :to="localePath('sponsors')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.friends')"
                  :to="localePath('friends')"
                />
              </li>
              <li>
                <nuxt-link
                  v-html="$t('menu.contact')"
                  :to="localePath('contact')"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      showMenu: false,
      activeMenu: ''
    }
  },
  watch: {
    $route(to, from) {
      this.showMenu = false
    }
  },
  methods: {
    changeMobilemenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

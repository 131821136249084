<template>
  <div :class="`embed_responsive embed_responsive__${aspectRatio}`">
    <iframe :src="src" :class="`embed_responsive__item ${extraClass}`" />
  </div>
</template>

<script>
export default {
  name: 'EmbedIframe',
  props: {
    src: {
      default: '',
      required: true,
      type: String
    },
    aspectRatio: {
      default: '16by9', // or: 21by9 / 4by3 / 1by1
      required: false,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>
<style lang="scss">
.embed_responsive {
  @apply relative block w-full p-0 overflow-hidden;

  &__21by9 {
    &:before {
      padding-top: 42.857143%;
    }
  }

  &__16by9 {
    &:before {
      padding-top: 56.25%;
    }
  }

  &__4by3 {
    &:before {
      padding-top: 75%;
    }
  }

  &__1by1 {
    &:before {
      padding-top: 100%;
    }
  }

  &:before {
    @apply block;
    content: '';
  }

  &__item {
    @apply absolute top-0 right-0 bottom-0 left-0 w-full h-full border-0;
  }
}
</style>

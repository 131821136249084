<template>
  <textarea
    :name="name"
    :id="`form_field_${name}`"
    :required="required"
    :placeholder="placeholder"
    :rows="rows"
    :class="`form_input ${extraClass}`"
    type="text"
  />
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    name: {
      default: 'text',
      required: true,
      type: String
    },
    required: {
      default: false,
      required: false,
      type: Boolean
    },
    placeholder: {
      default: '',
      required: false,
      type: String
    },
    rows: {
      default: '5',
      required: false,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

<template>
  <main id="about_dex">
    <header-page :photos="page.acf.header_images" spacer="black" />
    <section class="bg-black text-white py-8 tablet:py-24">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/2 px-4">
            <header-h2
              :content="page.acf.slogan"
              color="white"
              family="script"
              casing="uppercase"
              mt="12"
              mb="12"
            />
            <img
              :src="page.media.large"
              :alt="page.title"
              class="tablet:-mb-32 relative tablet-dex-image"
            />
          </div>
          <div class="w-full tablet:w-1/2 px-4">
            <div v-html="page.content" />
            <div class="mt-10 motor_info">
              <h4 class="mb-2">Over Dex</h4>
              <div
                v-for="(about, index) in page.acf.about"
                :key="index"
                class="flex flex-wrap -mx-4"
              >
                <div class="w-full tablet:w-1/3 px-4">
                  <strong v-html="about.label" />
                </div>
                <div class="w-full tablet:w-2/3 px-4">
                  <span v-html="about.value" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-8 tablet:py-24">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/2 px-4">
            <div v-html="page.acf.support" />
          </div>
          <div class="w-full tablet:w-1/2 px-4">
            <div v-html="page.acf.bike" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderPage from '../components/headers/Page'
import wordpress from '~/service/wordpress'

export default {
  name: 'AboutDex',
  components: { HeaderPage },
  data() {
    return {}
  },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Dex'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        },

        {
          property: 'og:image',
          content: this.page.media.medium
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.page.media.medium
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [page] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.about_dex')}`)
    ])

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

<template>
    <main id="friends">
        <header-page :photos="page.acf.header_images"/>
        <section class="py-16 pb-8">
            <div class="container">
                <div class="flex flex-wrap">
                    <div class="w-full px-4">
                        <header-h2
                            :content="page.title"
                            color="blue-dark"
                            casing="uppercase"
                            family="header"
                            align="center"
                        />
                        <div v-html="page.content"/>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import HeaderPage from '../components/headers/Page'
import wordpress from '~/service/wordpress'

export default {
    name      : 'Friends',
    components: {HeaderPage},

    head() {
        const title       =
                  this.page.yoast.yoast_wpseo_title === ''
                      ? this.page.title + ' - Dex'
                      : this.page.yoast.yoast_wpseo_title
        const description =
                  this.page.yoast.yoast_wpseo_metadesc === ''
                      ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
                      : this.page.yoast.yoast_wpseo_metadesc

        return {
            title,
            titleTemplate: title,
            meta         : [
                {
                    hid    : 'description',
                    name   : 'description',
                    content: description
                },
                {property: 'og:locale', content: 'en_GB'},
                {property: 'og:type', content: 'page'},
                {property: 'og:title', content: title},
                {property: 'og:description', content: description},
                {
                    property: 'og:url',
                    content : 'https://www.dashingdex.nl' + this.$route.fullPath
                },
                {property: 'og:site_name', content: 'Dex'},
                {
                    property: 'og:article:published_time',
                    content : this.page.date
                },
                {
                    property: 'og:article:modified_time',
                    content : this.page.date_modified
                },
                {
                    property: 'og:og:updated_time',
                    content : this.page.date_modified
                }
            ]
        }
    },

    async asyncData({app}) {
        const [page] = await Promise.all([
            wordpress.get(`page/${app.i18n.t('endpoints.become_sponsor')}`)
        ])

        return {
            page           : page.data,
            paymentReceived: false,
        }
    },

    mounted() {
        if (process.browser) {
            this.$gtag('event', 'your_event', {
                page_title: this.page.title,
                page_path : this.$route.fullPath
            })
        }

        if (location.search.indexOf('payment=done') !== -1) {
            this.paymentReceived = true;

            this.$nextTick(() => {
                document.getElementById('paymentReceivedMessage').scrollIntoView();
            })
        }
    },

    methods: {

        /**
         * Submit the form
         */
        submitForm() {
            let name  = this.$el.querySelector('[name="name"]').value;
            let email = this.$el.querySelector('[name="email"]').value;

            if (!name || !email) {
                return;
            }

            this.$axios
                .post('https://cms.dexvandenbroek.nl/payments/create.php', {name: name, email: email}, {
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
                .then(response => location.href = response.data)
                .catch(() => {
                    alert('Error');
                })
        },
    }
}
</script>

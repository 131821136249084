<template>
  <select
    :name="name"
    :id="`form_field_${name}`"
    :required="required"
    :class="`form_input ${extraClass}`"
  >
    <form-option value="" is-default="false" label="Selecteer optie" />
    <form-option
      v-for="(option, index) in options"
      :value="option.value"
      :key="index"
      v-html="option.label"
      :selected="option.default"
    />
  </select>
</template>

<script>
import FormOption from './option'
export default {
  name: 'FormSelect',
  components: { FormOption },
  props: {
    required: {
      default: false,
      required: false,
      type: Boolean
    },
    name: {
      default: '',
      required: true,
      type: String
    },
    id: {
      default: '',
      required: true,
      type: String
    },
    options: {
      default: () => [],
      required: true,
      type: Array
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

<template>
  <main id="sponsors">
    <header-page :photos="page.acf.header_images" />
    <section class="py-16 pb-8">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <header-h2
              :content="page.title"
              color="blue-dark"
              casing="uppercase"
              family="header"
              align="center"
            />
            <div v-html="page.content" />
          </div>
        </div>
      </div>
    </section>
    <section class="py-8">
      <div class="container">
        <client-only>
          <masonry
            :cols="{ default: 3, 768: 1 }"
            :gutter="0"
            class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
          >
            <div
              v-for="(post, index) in posts"
              :key="index"
              class="w-full mb-8 px-4"
            >
              <div v-if="post.acf.url">
                <a :href="post.acf.url" target="_blank">
                  <img :src="post.acf.logo.sizes.medium" :alt="post.title" />
                </a>
              </div>
              <div v-else>
                <img :src="post.acf.logo.sizes.medium" :alt="post.title" />
              </div>
            </div>
          </masonry>
        </client-only>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderPage from '../components/headers/Page'
import wordpress from '~/service/wordpress'
export default {
  name: 'Sponsors',
  components: { HeaderPage },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Dex'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.dashingdex.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'Dex' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [page, posts] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.sponsors')}`, {params: {order_by: 'menu_order',order: 'desc',page: 1,per_page: 99999}}),
      wordpress.get(`${app.i18n.t('endpoints.sponsor_items')}`, {params: {order_by: 'menu_order',order: 'desc',page: 1,per_page: 99999}})
    ])

    return {
      page: page.data,
      posts: posts.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

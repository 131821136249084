<template>
  <main>
    <header-small />
    <section class="bg-gray-500 py-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-7/10 tablet:mx-auto px-4">
            <header-h2
              :content="$t('404.header')"
              color="blue-dark"
              casing="uppercase"
              family="header"
              align="center"
            />
            <p v-html="$t('404.text')" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderSmall from '../components/headers/Small'
export default {
  name: 'Error',
  components: { HeaderSmall },
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  layout: 'default'
}
</script>
